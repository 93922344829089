:root{
  --background-color: hsl(240, 4%, 11%);
  --input-background: hsl(240, 3%, 21%);
  --title-color: hsl(342, 92%, 40%);
  --font-color: hsl(150, 100%, 98%);
  --gradient: hsl(335, 91%, 30%);
  --font: 'Roboto', sans-serif;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background-color: var(--background-color);
  font-family: var(--font);
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}


/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
   scroll-behavior: auto;
  }
  
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

button{
  cursor: pointer;
}

.App{
  display: grid;
  grid-template-areas: 
              "nav"
              "content"
              "footer";
  grid-template-rows: 5% 85% 10%;
  height: 100vh;
}

.nav{
  grid-area: nav;
  display: grid;
  padding: 1em;
  grid-template-columns: auto auto auto;
  color: var(--title-color);
  align-items: center;
  text-decoration: none;
}

.nav h1{
  background-image: linear-gradient(to right, var(--title-color), var(--gradient));
  background-clip: text;  
  color: transparent;
  font-size: 2rem;
}

.web-links a{
  text-decoration: none;
  color: var(--title-color);
  margin-left: 3rem;
  margin-right: 3rem;
}

.sign-links{
  grid-area: sign;
  grid-column: 3;
  grid-row: 1;
  text-align: end;
}

.mobile-hamburger{
  display: none;
  grid-column: 3;
  justify-self: end;
}

.logged-in{
  display: flex;
  flex-direction: row;
  gap: 2rem;
  justify-content: end;
  align-items: center;
}

.logged-in input{
  height: 2rem;
}

.sign-links a{
 color: var(--title-color);
}

.sign-links button{
  background-image: linear-gradient(to right, var(--title-color), var(--gradient));
  border: none;
  color: var(--font-color);
  margin-right: 1.5em;
  margin-left: 1.5rem;
  width: 6rem;
  height: 2rem;
  cursor: pointer;
}

.content{
  grid-area: content;
  color: var(--title-color);
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 50% 50%;
  text-align: center;
  margin-top: 3rem;
  align-items: center;
}

.welcome-title{
  grid-column: span 2;
  width: 100%;
}

.animation{
  grid-row: 2;
  width: 70%;
  justify-self: end;
}

.content p {
  grid-row: 2;
  grid-column: 2;
  width: 60%;
}

.hamburger-menu{
  display: grid;
  grid-template-rows: auto auto auto auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  top: 0;
  z-index: 100;
  background-color: var(--title-color);
  width: 100vw;
  height: 100vh;
  animation-name: hamburger-menu;
  animation-duration: 0.5s;
}

.hamburger-menu a{
  animation-name: hamburger-link;
  animation-duration: 3s;
  text-decoration: none;
  color: var(--font-color);
}

.hamburger-close{
  color: var(--font-color);
  justify-self: end;
}

.hamburger-background-close{
  animation-name: hamburger-menu-close;
  animation-duration: 0.5s;
}

/* Signup */

.signup-form{
  display: grid;
  grid-template-columns: 25% 50% 25%;
  grid-template-rows: 10% 10% 10% 10% 10%;
  grid-gap: 0.5rem;
  height: 65vh;
  align-items: center;
  justify-items: center;
}

.form-name{
  grid-column: 2;
  width: 20rem;
  height: 3.5rem;
  background-color: var(--input-background);
  border: none;
  border-radius: 0.3em;
}

::placeholder{
  color: var(--font-color);
}

.signup-form button{
  grid-column: 2;
  width: 22rem;
  height: 80%;
  border: none;
  background-image: linear-gradient(to right, var(--title-color), var(--gradient));
  color: var(--font-color);
  border-radius: 0.3em;
}

.signup-requirements{
  grid-column: 2;
  grid-row: 6;
  color: var(--title-color);
  font-size: 0.5rem;
  align-self: flex-start;
}

.invalid-signup{
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: 2;
  grid-row: 7;
  align-self: flex-start;
  color: var(--title-color);
  text-align: center;
}

/* Bundles page */
.bundles{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
}

.bundles a {
  text-decoration: none;
  color: var(--title-color);
  margin-bottom: 8rem;
}

.bundle-info{
  display: grid;
  align-items: center;
  justify-content: center;
}

/* Games */
.game-container{
  display: grid;
  justify-content: center;
  grid-template-rows: auto auto auto auto auto auto auto auto auto auto;
}

.game-info-list{
  display: grid;
  grid-template-columns: 30% 15% 15% 40%;
  font-size: 0.8rem;
  word-wrap: break-word;
  border: 0.1rem var(--title-color) solid;
  overflow: hidden;
  grid-gap: 1rem;
  color: var(--font-color);
  padding: 2rem;
  align-items: center;
}

/* Search page */
.search-game-container-box{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--title-color);
}

.search-game-container{
  background-color: var(--input-background);
  border-radius: 1rem;
  margin: 2rem 2rem 2rem 2rem;
}


.search-game-container a{
  text-decoration: none;
  color: var(--title-color);
}

.search-game-info {
  display: flex;
  width: 25rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.search-game-info img{
  width: 10rem;
}

/* PROFILE */
.profile-container{
  display: flex;
  flex-direction: column;
  text-align: center;
  color: var(--title-color);
}

.profile-header{
  display: grid;
  grid-template-columns: 50% 25%;
  justify-content: end;
}

.profile-header button{
  width: 5rem;
  height: 2rem;
  justify-self: end;
  margin-right: 2rem;
  font-size: 1rem;
  background-image: linear-gradient(to right, var(--title-color), var(--gradient));
  border: none;
  color: white;
}

.alert-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.alert-container-info{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5rem;
}

.alert-container-info button{
  background-image: linear-gradient(to right, var(--title-color), var(--gradient));
  border: none;
  color: var(--font-color);
}

/* FOOTER */

.footer{
  grid-area: footer;
  background-color: var(--title-color);
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  grid-gap: 2rem;
}

.footer a{
  color: white;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 900px) {
  .content{
      display: flex;
      flex-direction: column;
  }

  .nav h1{
    font-size: 1.4rem;
  }

  .web-links{
    display: none;
  }

  .sign-links{
    display: none;
  }

  .mobile-hamburger{
    display: block;
    z-index: 100;
    color: white;
  }

  .game-info-list{
    font-size: 0.3rem;
    padding: 1rem;
    overflow: visible;
  }
}

/* Animations */
@keyframes hamburger-menu {
  from{
    width: 0%;
    background-color: transparent;
  }
  to{
    width: 100%;
    background-color: var(--title-color);
  }
}

@keyframes hamburger-link {
  from{
    color: transparent;
  }
  to{
    color: var(--font-color);
  }
}

@keyframes hamburger-menu-close {
  from{
    width: 100%;
    background-color: var(--title-color);
  }
  to{
    width: 0%;
    background-color: transparent;
  }
}

